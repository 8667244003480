import PropTypes from "prop-types";
import { List, Stack } from "@mui/material";
import { useLocales } from "../../../locales";
import { StyledSubheader } from "./styles";
import NavList from "./NavList";
import { useAuthContext } from "src/auth/useAuthContext";

NavSectionVertical.propTypes = {
  sx: PropTypes.object,
  data: PropTypes.array,
};

export default function NavSectionVertical({ data, sx, ...other }) {
  const { translate } = useLocales();
  const { user } = useAuthContext();

  let filteredData = data.map((group) => {
    let items = group.items;

    if (!user?.venue?.configurations?.isTableFeatureAvailed) {
      items = items.filter((item) => item.title !== "tables");
    }
    if (!user?.permissions?.canViewDashboard) {
      items = items.filter((item) => item.title !== "dashboard");
    }
    if (!user?.permissions?.canViewStaff) {
      items = items.filter((item) => item.title !== "staff");
    }
    if (!user?.permissions?.canAccessReports) {
      items = items.filter((item) => item.title !== "report");
    }

    if (!user?.venue?.configurations?.isDeliveryAvailableInPos) {
      items = items.filter((item) => item.title !== "customers");
    }

    if (
      (!user?.venue?.configurations?.isReservation &&
        !user?.venue?.configurations?.isTableFeatureAvailed &&
        !user?.venue?.configurations?.isOrderingFeatureAvailed &&
        !user?.venue?.configurations?.isKitchenFeatureAvailed &&
        !user?.venue?.configurations?.isPOSAvailable) ||
      (!user?.permissions?.canViewPos &&
        !user?.permissions?.canSeeReservationCalendar &&
        !user?.permissions?.canEditOrderOnPOS && 
        !user?.permissions?.canSeeOrderProcessingScreen &&
        !user?.permissions?.canSeeKitchenScreen)
    ) {
      items = items.filter((item) => item.title !== "terminal");
    }
    items = items.map((item) => {
      if (item.title === "terminal") {
        if (
          user?.venue?.configurations?.isPOSAvailable &&
          user?.permissions?.canViewPos
        ) {
          item.path = "/dashboard/pos";
        } else if (
          user?.venue?.configurations?.isReservation &&
          user?.permissions?.canSeeReservationCalendar
        ) {
          item.path = "/dashboard/reservation/calendar";
        } else if (
          user?.venue?.configurations?.isTableFeatureAvailed &&
          user?.permissions?.canEditOrderOnPOS
        ) {
          item.path = "/dashboard/tables";
        } else if (
          user?.venue?.configurations?.isOrderingFeatureAvailed &&
          user?.permissions?.canSeeOrderProcessingScreen
        ) {
          item.path = "/dashboard/orders";
        } else if (
          user?.venue?.configurations?.isKitchenFeatureAvailed &&
          user?.permissions?.canSeeKitchenScreen
        ) {
          item.path = "/dashboard/kitchen";
        }
      }
      return item;
    });

    if (!user?.venue?.configurations?.isInventoryAvailable) {
      items = items.filter((item) => item.title !== "inventory");
    }

    if (!user?.permissions?.canViewVenueCustomers) {
      items = items.filter((item) => item.title !== "customers");
    }

    if (!user?.permissions?.canViewLedger) {
      items = items.filter((item) => item.title !== "Ledger");
    }

    return { ...group, items };
  });

  // filteredData =
  //   user.permissions?.canViewStaff == "true"
  //     ? filteredData
  //     : filteredData.map((group) => ({
  //         ...group,
  //         items: group.items.filter((item) => item.title !== "staff"),
  //       }));

  return (
    <Stack sx={sx} {...other}>
      {filteredData.map((group) => {
        const key = group.subheader || group.items[0].title;

        return (
          <List key={key} disablePadding sx={{ px: 2 }}>
            {group.subheader && (
              <StyledSubheader disableSticky>{`${translate(
                group.subheader
              )}`}</StyledSubheader>
            )}

            {group.items.map((list) => (
              <NavList
                key={list.title + list.path}
                data={list}
                depth={1}
                hasChild={!!list.children}
              />
            ))}
          </List>
        );
      })}
    </Stack>
  );
}
