import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  tables: [],
  tablesObj: null,
  table: null,
};

const slice = createSlice({
  name: "table",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CATEGORIES
    getTablesSuccess(state, action) {
      state.isLoading = false;
      state.tables = action.payload;
    },

    // GET CATEGORIES
    getTablesObjSuccess(state, action) {
      state.isLoading = false;
      state.tablesObj = action.payload;
    },

    // GET CATEGORY
    getTableSuccess(state, action) {
      state.isLoading = false;
      state.table = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
// export const {
// } = slice.actions;

// ----------------------------------------------------------------------

export function getTables(levelId, venueId, isAvailable) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `${backendUrl}/tables?levelId=${levelId}&venueId=${venueId}&limit=9999`;
      if (isAvailable) {
        url = url + `&isAvailable=${isAvailable}`;
      }
      const response = await axios.get(url);
      dispatch(slice.actions.getTablesSuccess(response.data.results));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getTablesWithAreaAndOrder(levelId, venueId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${backendUrl}/tables/getTablesWithAreaAndOrder?levelId=${levelId}&venueId=${venueId}`
      );
      dispatch(slice.actions.getTablesObjSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getTable(tableId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${backendUrl}/tables/${tableId}`);
      dispatch(slice.actions.getTableSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function freeTable(item) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${backendUrl}/posCart/freeTable`, {
        tableId: item?.id,
        orderId: item?.currentOrderId?.id,
      });
      const data = {
        orderId: item?.currentOrderId?.id,
        state: "completed",
        orderStateChangeTime: {
          TableFreed: new Date()
        }
      }
      await axios.post(`${backendUrl}/cart/changeOrderState`, data);
      dispatch(slice.actions.getTableSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
