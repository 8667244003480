import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
const backendUrl = process.env.REACT_APP_BACKEND_URL;
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  venues: [],
  venue: null
};

const slice = createSlice({
  name: 'venue',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CATEGORIES
    getVenuesSuccess(state, action) {
      state.isLoading = false;
      state.venues = action.payload;
    },

    // GET CATEGORY
    getVenueSuccess(state, action) {
      state.isLoading = false;
      state.venue = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
// export const {
// } = slice.actions;

// ----------------------------------------------------------------------

export function getVenues(levelId, venueId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${backendUrl}/venues?levelId=${levelId}&venueId=${venueId}`);
      dispatch(slice.actions.getVenuesSuccess(response.data.results));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getVenue(venueId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${backendUrl}/venues/${venueId}`);
      dispatch(slice.actions.getVenueSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
